import React, { useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../components/Seo';

import RichText from '../components/shared/RichText';
import W from '../components/styled/Wrapper';
import H from '../components/styled/Heading';

const Wrapper = styled(W)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 120px);
  min-height: calc((var(--vh, 1vh) * 100) - 120px);

  @media only screen and (max-width: 810px) {
    justify-content: flex-start;
  }
`;

const Heading = styled(H)`
  padding: 10px 0 30px;
`;

const ContentWrapper = styled.div`
  width: 50%;

  @media only screen and (max-width: 830px) {
    width: 100%;
  }
`;

const Policy = ({ data, contextData, contextSet, langTo }) => {
  useEffect(() => {
    contextSet({ langTo: langTo });
  }, []);

  return (
    <Wrapper>
      <SEO
        title={data.seoTitle}
        description={data.seoDescription}
        image={data.seoImage}
      />
      <Heading size={3}>{data.seoTitle}</Heading>
      <ContentWrapper>
        <RichText content={data.content.raw} firstParMargin small />
      </ContentWrapper>
    </Wrapper>
  );
};

export default Policy;
